<template>
  <div class="aside-enabled" ref="ktAside">
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <!--begin::Aside-->
        <div
          id="kt_aside"
          class="aside"
          data-kt-drawer="true"
          data-kt-drawer-name="aside"
          data-kt-drawer-activate="{default: true, lg: false}"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction="start"
          data-kt-drawer-toggle="#kt_aside_mobile_toggle"
        >
          <!--begin::Aside Toolbarl-->
          <div class="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
            <!--begin::Aside user-->
            <!--begin::User-->
            <div
              class="aside-user d-flex align-items-sm-center justify-content-center py-5 align-items-center"
            >
              <!--begin::Symbol-->
              <div class="symbol symbol-50px">
                <img src="/assets/media/avatars/admin-sistem.jpeg" alt="" />
              </div>
              <!--end::Symbol-->
              <!--begin::Wrapper-->
              <div class="aside-user-info flex-row-fluid flex-wrap ms-5">
                <!--begin::Section-->
                <div class="d-flex">
                  <!--begin::Info-->
                  <div class="flex-grow-1 me-2">
                    <!--begin::Username-->
                    <a
                      href="#"
                      @click="$router.push({ name: 'profile' })"
                      class="text-white text-hover-primary fs-6 fw-bold"
                    >
                      {{ currentUser ? currentUser.username : "-" }}
                    </a>
                    <!--end::Username-->
                    <!--begin::Description-->
                    <span class="text-gray-600 fw-semibold d-block fs-8 mb-1">
                      {{ userRole }}
                    </span>
                    <!--end::Description-->
                    <!--begin::Label-->
                    <div class="d-flex align-items-center text-success fs-9">
                      <!--<span class="bullet bullet-dot bg-success me-1"></span>online-->
                    </div>
                    <!--end::Label-->
                  </div>
                  <!--end::Info-->
                  <!--begin::User menu-->
                  <div class="me-n2">
                    <!--begin::Action-->
                    <a
                      href="#"
                      class="btn btn-icon btn-sm btn-active-color-primary mt-n2"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-start"
                      data-kt-menu-overflow="true"
                      @click="logout"
                    >
                      <i class="ki-duotone ki-setting-2 text-muted fs-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </i>
                    </a>
                    <!--begin::User account menu-->
                    <div
                      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                      data-kt-menu="true"
                    >
                      <!--begin::Menu item-->
                      <div class="menu-item px-5">
                        <a href="index.html" class="menu-link px-5">Keluar</a>
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::User account menu-->
                    <!--end::Action-->
                  </div>
                  <!--end::User menu-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Wrapper-->
            </div>
            <!--end::User-->
          </div>
          <!--end::Aside Toolbarl-->
          <div id="include-menu">
            <div class="aside-menu flex-column-fluid">
              <!--begin::Aside Menu-->
              <div
                class="hover-scroll-overlay-y mx-3 my-5 my-lg-5"
                id="kt_aside_menu_wrapper"
                data-kt-scroll="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}"
                data-kt-scroll-wrappers="#kt_aside_menu"
                data-kt-scroll-offset="5px"
              >
                <!--begin::Menu-->
                <div
                  class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                  id="#kt_aside_menu"
                  data-kt-menu="true"
                >
                  <!--begin:Menu item-->
                  <div class="menu-item" v-for="(menu, i) in menus" :key="i">
                    <span v-if="menu.link == '#'">
                      <div class="menu-content">
                        <span class="menu-heading fw-bold text-uppercase fs-7">
                          {{ menu.label }}
                        </span>
                      </div>
                    </span>
                    <!--begin:Menu link-->
                    <router-link
                      v-else
                      :class="
                        $route.path == menu.link
                          ? 'menu-link active'
                          : 'menu-link'
                      "
                      :to="menu.link"
                    >
                      <!-- <a class="menu-link" :href="menu.link"> -->
                      <span class="menu-icon active">
                        <i class="ki-duotone ki-element-11 fs-2">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                        </i>
                      </span>
                      <span class="menu-title">{{ menu.label }}</span>
                      <!-- </a> -->
                    </router-link>
                    <!--end:Menu link-->
                  </div>
                  <!--end:Menu item-->
                </div>
                <!--end::Menu-->
              </div>
              <!--end::Aside Menu-->
            </div>
          </div>
          <!--begin::Footer-->
          <div
            class="aside-footer flex-column-auto py-5"
            id="kt_aside_footer"
          ></div>
          <!--end::Footer-->
        </div>
        <!--end::Aside-->
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <!--begin::Header-->
          <div id="kt_header" style="" class="header align-items-stretch">
            <!--begin::Brand-->
            <div class="header-brand">
              <!--begin::Logo-->
              <a
                class="d-flex align-items-sm-center justify-content-center py-5 align-items-center"
                href="index.html"
              >
                <img
                  alt="Logo"
                  src="/assets/media/logos/tni-logo.png"
                  class="h-50px h-lg-50px"
                />
                <span class="fs-2 text-white px-2 pt-5">Cetak KTA</span>
              </a>
              <!--end::Logo-->
              <!--begin::Aside minimize-->
              <div
                ref="ktAsideToggle"
                id="kt_aside_toggle"
                class="btn btn-icon w-auto px-0 btn-active-color-success aside-minimize"
                data-kt-toggle="true"
                data-kt-toggle-state="active"
                data-kt-toggle-target="body"
                data-kt-toggle-name="aside-minimize"
                @click="minimizeAside()"
              >
                <i
                  class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default"
                >
                  <span class="path1"></span>
                  <span class="path2"></span>
                </i>
                <i class="ki-duotone ki-entrance-left fs-1 minimize-active">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </i>
              </div>
              <!--end::Aside minimize-->
              <!--begin::Aside toggle-->
              <div
                class="d-flex align-items-center d-lg-none me-n2"
                title="Show aside menu"
              >
                <div
                  class="btn btn-icon btn-active-color-primary w-30px h-30px"
                  id="kt_aside_mobile_toggle"
                >
                  <i class="ki-duotone ki-abstract-14 fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
              </div>
              <!--end::Aside toggle-->
            </div>
            <!--end::Brand-->
            <!--begin::Toolbar-->
            <div class="toolbar d-flex align-items-stretch">
              <!--begin::Toolbar container-->
              <div
                class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between"
              >
                <!--begin::Page title-->
                <div
                  class="page-title d-flex justify-content-center flex-column me-5"
                >
                  <!--begin::Title-->
                  <h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">
                    {{ $route.meta.title ? $route.meta.title : "Dashboard" }}
                  </h1>
                  <!--end::Title-->
                  <!--begin::Breadcrumb-->
                  <ul
                    v-if="$route.meta.subTitle"
                    class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 pt-1"
                  >
                    <template
                      v-for="(subtitle, i) in $route.meta.subTitle"
                      :
                      :key="i"
                    >
                      <li class="breadcrumb-item text-dark">{{ subtitle }}</li>
                      <li class="breadcrumb-item">
                        <span class="bullet bg-gray-300 w-5px h-2px"></span>
                      </li>
                    </template>
                  </ul>
                  <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
              </div>
              <!--end::Toolbar container-->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Header-->
          <!--begin::Content-->
          <div
            class="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <router-view />
          </div>
          <!--end::Content-->
          <!--begin::Footer-->
          <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <!--begin::Container-->
            <div
              class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
            >
              <!--begin::Copyright-->
              <div class="text-dark order-2 order-md-1">
                <span class="text-muted fw-semibold me-1">2024&copy;</span>
                <a
                  href="https://tni.mil.id"
                  target="_blank"
                  class="text-gray-800 text-hover-primary"
                  >Tentara Nasional Indonesia</a
                >
              </div>
              <!--end::Copyright-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Footer-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
  </div>
</template>

<style>
#include-menu {
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>

<script>
export default {
  mounted() {
    this.parseCurrentUser();
  },
  data() {
    return {
      currentUser: null,
      userRole: "",
      menus: [
        {
          label: "Manage User",
          link: "/admin/user",
        },
        {
          label: "Manage Role",
          link: "/admin/role",
        },
        {
          label: "Manage Permission",
          link: "/admin/permission",
        },
      ],
    };
  },
  methods: {
    parseCurrentUser() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.currentUser = user;
      const { roles } = user;
      this.userRole = roles.map((r) => r.name).toString();
      let menus = [];
      roles.forEach((role) => {
        menus = [...menus, ...role.menus];
      });
      const uniqueMenus = [...new Set(menus)]
        .map((m) => {
          return {
            label: m.label,
            link: m.url,
            ordering: m.ordering,
          };
        })
        .sort((a, b) => a.ordering - b.ordering);
      this.menus = uniqueMenus;
    },
    logout() {
      // dialog config
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Logout from this app!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem("user");
            this.$swal.fire({
              title: "Logout!",
              text: "Success...",
              icon: "success",
            });
            location.reload();
          }
        });
    },
    minimizeAside() {
      console.log("clicked");
      const ktAsideElement = this.$refs.ktAside;
      const ktAsideToggleElement = this.$refs.ktAsideToggle;

      ktAsideToggleElement.classList.toggle("active");
      const attributeName = "data-kt-aside-minimize"; // replace with your attribute name
      if (ktAsideElement.hasAttribute(attributeName)) {
        ktAsideElement.removeAttribute(attributeName);
      } else {
        ktAsideElement.setAttribute(attributeName, "on"); // replace 'value' with the value you want to set
      }
    },
  },
};
</script>
